import { Link } from "gatsby";
import React from "react";
import { ArrowLeft } from "react-feather";
import { EditUser } from "../../../components/EditUser";

interface Props {
    uid: string,
};

const UserPage = ({ uid }: Props) => {
    return (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4">
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-my-8">
                <h3 className="tw-text-safari-green tw-text-4xl tw-font-bold">
                    CloudSafari Profile Details
                </h3>
                <h5 className="tw-text-lg tw-mt-2">
                    Use the fields below to edit basic details for your profile.
                </h5>
            </div>
            <div className="tw-mb-8 tw-w-full tw-max-w-[1000px]">
                <Link to="/user" className="tw-flex"><ArrowLeft className="tw-mr-2" /> Return Home</Link>
            </div>
            <div className="tw-w-full tw-max-w-[1000px]">                
            <EditUser Uid={uid} Organization={false} />
            </div>
        </div>
    )
};

export default UserPage;